import Input from '@components/shared/input'
import NumberFormat from '@components/shared/Number'
import { MeasuredUnit } from '@constants/rawMaterial'
import { ReceiptBlockType } from '@customTypes/block'
import { GetReceiptsType, ReceiptBaseType } from '@customTypes/receipt'
import React from 'react'

type SearchParams<T> = {
  params: GetReceiptsType
  data?: T[]
}

export const onFilterReceipt = <T extends ReceiptBaseType>(params: SearchParams<T>) => {
  const {
    params: { field, query },
    data = [],
  } = params

  if (!query || !field || !data) {
    return data
  }

  if (['name', 'description'].includes(field)) {
    const keyField = field as 'name' | 'description'
    return data.filter((item) => item[keyField].toLowerCase().includes(query.toLowerCase()))
  }

  if (field === 'uid') {
    return data.filter((item) => item.uid.startsWith(query))
  }

  return data
}

type ReceiptResumenParams = {
  index?: number
  reduced?: boolean
  receipt: ReceiptBlockType
  handleInput?: (path: string, value: number | string) => void
}

export const getReceiptResumen = (params: ReceiptResumenParams) => {
  const { receipt, reduced, index, handleInput } = params
  const { rawMaterials } = receipt

  return (
    <React.Fragment>
      <ol>
        {rawMaterials.map((material, idx) => {
          if (reduced) {
            return (
              <li key={material.uid}>
                {material.name} {material.quantity} {MeasuredUnit[material.measure].value}
              </li>
            )
          }

          return (
            <li className="cd-my-[0.75rem]" key={material.uid}>
              {material.name}: {material.quantity} {MeasuredUnit[material.measure].value}
              {handleInput && (
                <Input
                  defaultValue={material.value}
                  min={0}
                  prefix="$"
                  typeInput="number"
                  variant="default"
                  onChange={(value) =>
                    handleInput(`items.${index}.rawMaterials.${idx}.value`, value)
                  }
                />
              )}
              {!handleInput && (
                <strong>
                  Valor: <NumberFormat value={material.value} />
                </strong>
              )}
            </li>
          )
        })}
      </ol>
      <span>
        Total: <NumberFormat value={rawMaterials.reduce((acc, item) => acc + item.value, 0)} />
      </span>
    </React.Fragment>
  )
}
