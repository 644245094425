import React from 'react'
import moment from 'moment'
import { Image } from '@mantine/core'

import { ProformaBaseType } from '@customTypes/proforma'

import { DATE_FORMAT_SHORT } from '@constants/app'
import { DueDateOptions, ProformaSeinpro } from '@constants/proforma'

import { $ } from '@utils/styles'
import { getDescription, getProformaTotal, getSequentialStr } from '@utils/proforma'

import NumberFormat from '@components/shared/Number'

import './pdf.scss'

type PDFProps = {
  className?: string
  generating?: boolean
  proforma: ProformaBaseType
  totals: [number, number, number]
}

const ProformaPDF = React.forwardRef<HTMLDivElement, PDFProps>((props, ref) => {
  const { proforma, totals, className } = props

  return (
    <div className={$('cd-table-df cd-flex cd-flex-col cd-gap-y-[1rem]', className)} ref={ref}>
      <div className="cd-flex cd-justify-between cd-items-center">
        <div className="cd-flex cd-flex-col">
          <Image alt="logo" className="cd-my-[1rem]" h="auto" src="/SeinproColor.png" w={175} />
        </div>
        <div>
          <table className="!cd-w-[9rem]">
            <thead className="">
              <th className="!cd-pb-[0.5rem]">Cotización</th>
            </thead>
            <tbody>
              <tr>
                <td className="!cd-text-center !cd-pb-[0.5rem]">
                  <p>{getSequentialStr(proforma.sequential)}</p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div>
        <div className="cd-flex cd-flex-row cd-gap-x-[0.25rem]">
          <p className="cd-font-bold">RUC:</p>
          <p>{ProformaSeinpro.RUC}</p>
        </div>
        <div className="cd-flex cd-flex-row cd-gap-x-[0.25rem]">
          <p className="cd-font-bold">Dirección:</p>
          <p>{ProformaSeinpro.location}</p>
        </div>
        <div className="cd-flex cd-flex-row cd-gap-x-[0.25rem]">
          <p className="cd-font-bold">Teléfonos:</p>
          <p>{ProformaSeinpro.telfs}</p>
        </div>
        <div className="cd-flex cd-flex-row cd-gap-x-[0.25rem]">
          <p className="cd-font-bold">Email:</p>
          <p>{ProformaSeinpro.email}</p>
        </div>
      </div>

      <div className="cd-flex cd-flex-col cd-gap-y-[1rem]">
        <div>
          <table>
            <thead>
              <tr>
                <th className="cd-w-[45%]">Cliente</th>
                <th className="cd-w-[31%]">Contacto</th>
                <th className="cd-w-[12%]">RUC</th>
                <th className="cd-w-[12%]">Fecha</th>
              </tr>
            </thead>
            <tbody>
              <tr className="cd-none-bottom-border">
                <td>{proforma.client.name}</td>
                <td>{proforma.client.email}</td>
                <td>{proforma.client.ruc}</td>
                <td className="!cd-text-right">{moment().format(DATE_FORMAT_SHORT)}</td>
              </tr>
            </tbody>
          </table>
          <table>
            <thead>
              <tr>
                <th className="cd-w-[15%]">Teléfono</th>
                <th className="cd-w-[45%]">Dirección</th>
                <th className="cd-w-[28%]">Email</th>
                <th className="cd-w-[12%]">Validez</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{proforma.client.phone}</td>
                <td>{proforma.client.location}</td>
                <td>{proforma.client.email}</td>
                <td className="!cd-text-right">
                  {proforma.dueDate.amount} {DueDateOptions[proforma.dueDate.unit].label}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div>
          <table className="cd-none-bottom-border">
            <thead>
              <tr>
                <th>Item</th>
                <th>Descripción</th>
                <th className="cd-w-[10%]">Cantidad</th>
                <th className="cd-w-[12%]">P. Unitario</th>
                <th className="cd-w-[12%]">Total</th>
              </tr>
            </thead>
            <tbody className="cd-none-row-separator">
              {proforma.items.map((item, index) => (
                <tr
                  className={$(
                    'cd-row-highlight',
                    index === proforma.items.length - 1 && 'cd-table-separator',
                  )}
                  key={`item-${index}`}
                >
                  <td>{item.name}</td>
                  <td>
                    {!['raw-material', 'accessory'].includes(item.type) && (
                      <p>{item.description}</p>
                    )}
                    {getDescription({
                      reduced: true,
                      item,
                    })}
                  </td>
                  <td className="!cd-text-right">
                    <NumberFormat decimals={0} prefix="" value={item.quantity} />
                  </td>
                  <td className="!cd-text-right">
                    <NumberFormat
                      value={getProformaTotal({
                        itemsProforma: proforma.items,
                        unit: true,
                        item,
                      })}
                    />
                  </td>
                  <td className="!cd-text-right">
                    <NumberFormat
                      value={getProformaTotal({
                        itemsProforma: proforma.items,
                        item,
                      })}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <table>
            <tbody>
              <tr>
                <td className="cd-w-[20%]" colSpan={3}>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: proforma.description,
                    }}
                  />
                </td>
                <td className="cd-w-[12%]" rowSpan={2} />
                <td className="cd-w-[12%]" rowSpan={2} />
              </tr>
              <tr>
                <td colSpan={3}>{proforma.warranty}</td>
              </tr>
              <tr key="subtotal-row">
                <td className="cd-w-[40%]" rowSpan={3}>
                  <div className="cd-flex cd-flex-col cd-justify-start cd-h-full">
                    <p className="cd-text-base cd-font-bold cd-text-start">
                      Condiciones comerciales
                    </p>
                    <p>
                      Fecha de entrega: {proforma.dueDate.amount}{' '}
                      {DueDateOptions[proforma.dueDate.unit].label}
                    </p>
                    <p>Forma de pago:</p>
                    <ul>
                      {proforma.payments.map((method, index) => (
                        <li key={`payment-${index}`}>
                          {method.text} {method.percentage}%
                        </li>
                      ))}
                    </ul>
                  </div>
                </td>
                <td colSpan={2} rowSpan={3}>
                  <p className="cd-text-base cd-font-bold cd-text-start">
                    Datos para transferencia/depósitos
                  </p>
                </td>
                <td className="cd-font-bold cd-w-[10%]">Subtotal</td>
                <td className="cd-font-bold !cd-text-right cd-w-[10%]">
                  <NumberFormat value={totals[0]} />
                </td>
              </tr>
              <tr key="iva-row">
                <td className="cd-font-bold cd-w-[10%]">IVA ({proforma.iva ?? 0}%)</td>
                <td className="cd-font-bold !cd-text-right cd-w-[10%]">
                  <NumberFormat value={totals[1]} />
                </td>
              </tr>
              <tr key="total-row">
                <td className="cd-font-bold cd-w-[10%]">Total</td>
                <td className="cd-font-bold !cd-text-right cd-w-[10%]">
                  <NumberFormat value={totals[2]} />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
})

ProformaPDF.displayName = 'ProformaPDF'

export default ProformaPDF
