export const capitalize = (s: string, onlyFirst = false) => {
  if (onlyFirst) {
    return s.charAt(0).toUpperCase() + s.slice(1)
  }
  return s
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ')
}

export const cleanNumber = (value: string) => {
  return value.replace(/[^0-9.]/g, '')
}

export const getColorStock = (stock: number, alertStock: number) => {
  if (stock <= 0) return 'red'
  if (stock <= alertStock) return 'yellow'

  return 'inherit'
}

export const validateFormat = (value: string, regex: RegExp) => {
  return regex.test(value)
}
