import Input from '@components/shared/input'
import NumberFormat from '@components/shared/Number'
import { MeasuredUnit } from '@constants/rawMaterial'
import { BlockProformaType } from '@customTypes/proforma'
import React from 'react'

type BlockResumenParams = {
  index?: number
  reduced?: boolean
  block: BlockProformaType
  handleInput?: (path: string, value: number | string) => void
}

export const getBlockResumen = (params: BlockResumenParams): JSX.Element => {
  const { block, reduced, index, handleInput } = params

  const { receipts } = block

  return (
    <React.Fragment>
      <ol>
        {receipts.map((receipt, idxReceipt) => (
          <li key={receipt.uid}>
            {receipt.name}
            <ul>
              {receipt.rawMaterials.map((material, idxRm) => {
                if (reduced) {
                  return (
                    <li key={material.uid}>
                      {material.name} {material.quantity} {MeasuredUnit[material.measure].value}
                    </li>
                  )
                }

                return (
                  <li className="cd-my-[0.75rem]" key={material.uid}>
                    {material.name}: {material.quantity} {MeasuredUnit[material.measure].value}
                    {handleInput && (
                      <Input
                        defaultValue={material.value}
                        min={0}
                        prefix="$"
                        typeInput="number"
                        variant="default"
                        onChange={(value) =>
                          handleInput(
                            `items.${index}.receipts.${idxReceipt}.rawMaterials.${idxRm}.value`,
                            value,
                          )
                        }
                      />
                    )}
                    {!handleInput && (
                      <strong>
                        Valor: <NumberFormat value={material.value} />
                      </strong>
                    )}
                  </li>
                )
              })}
            </ul>
          </li>
        ))}
      </ol>
      <span>
        Total:{' '}
        <NumberFormat
          value={receipts.reduce(
            (acc, item) => acc + item.rawMaterials.reduce((acc, item) => acc + item.value, 0),
            0,
          )}
        />
      </span>
    </React.Fragment>
  )
}
